(function ($) {
  require('./arrangement_filter.js');

  $(document).ready(function () {
    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 4000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>",
      ],

      dots: false,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });

    var windowWidth = $(window).width();
    if (windowWidth > 980) {
      $(window).scroll(function () {
        if ($(window).scrollTop() > 0) {
          $("header").addClass("position-fixed");
          $("header").addClass("sticky");
          $("body").addClass("sticky");
        } else {
          $("header").removeClass("sticky");
          $("header").removeClass("position-fixed");
          $("body").removeClass("sticky");
        }
      });
    }
  });

  //   smoothscroll
  $(".location_detail .btn-scroll, .accommodation_detail .btn-scroll").click(
    function (e) {
      e.preventDefault();

      $("html, body").animate(
        {
          scrollTop: $(".search_book, .availability").offset().top - 70,
        },
        "fast"
      );
    }
  );
})(jQuery);

// Blog masonry

function resizeAllGridItems() {
  let item = document.querySelectorAll(".item");
  item.forEach((el, i) => {
    let size = el.childNodes[0].classList[1];
    if (size === "blog_overview_large") {
      el.style.gridRowEnd = "span 3";
    }
    else if (size === "blog_overview_medium") {
      el.style.gridRowEnd = "span 2";
    }
    else if  (size === "blog_overview_small"){
      el.style.gridRowEnd = "span 1";
    }
    else {
      //do noting
    }
  });
}

window.onload = resizeAllGridItems();
window.addEventListener("resize", resizeAllGridItems());
