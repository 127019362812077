$(document).on('change', '#f-country, #f-region, #f-park', function() {
  var $country = $('#f-country');
  var $region = $('#f-region');
  var $park = $('#f-park');
  var queryParts = [];

  if ($country.val()) {
    queryParts.push(`filters[country]=${$country.val()}`);
  }

  if ($region.val()) {
    queryParts.push(`filters[region]=${$region.val()}`);
  }

  if ($park.val()) {
    queryParts.push(`filters[park]=${$park.val()}`);
  }

  document.location = $country.data('baseUri')+`?${queryParts.join('&')}`;
});
